import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Chi Siamo' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
Siamo una claim company con l'obiettivo di fornire assistenza specialistica ai passeggeri aerei che subiscono un disservizio.
Siamo in prima linea nella difesa e tutela dei diritti del passeggiero. 
Sono migliaia, ad oggi, le richieste di rimborso evase giornalmente e di queste <b>ben il 99%</b> ad un esito positivo, 
ovvero hanno fatto ottenere il risarcimento integrale da parte della Compagnia per il disservizio subito. 
Eh sì, perché <b>eurorimborso.it</b>, oltre ad essere un servizio eccellente, è anche a zero spese per il passeggero
      </Text>
    </Card>
  </Section>
)

export default Commitment
