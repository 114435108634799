import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Section from '@components/Section'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Conttataci'  lang="it" description="Hai subito un disagio aereo. Contatta Eurorimborso per ottenere il Risarcimento"/>
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Il tuo volo ha subito un ritardo o è stato cancellato?"
          subheader='Ottieni fino a 
          600 € di risarcimento
        per passeggero, a prescindere dal prezzo del biglietto. Se non vinciamo, non ci devi nulla!'
          
        />
   
   
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
